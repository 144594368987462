import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbContextMenuDirective,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { Subject, Subscription, filter, map } from "rxjs";
import { CoreProvider } from "../../../services/services/core.provider";
import { Router } from "@angular/router";
import { ProfileModelDto, ReducedWorkspaceDto } from "../../../services/api/models";
import { ComponentsService } from "../../../services/services/components.service";
import { environment } from "../../../../environments/environment";

interface ExtendedNbMenuItem extends NbMenuItem {
  action?: () => void; // Añade la propiedad de acción opcional
}

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userDataSubscription: Subscription;
  userData: ProfileModelDto;
  workSpaceSubscription: Subscription;

  userPictureOnly: boolean = false;
  user: any;

  workspace: ReducedWorkspaceDto;
  isUserAdmin: boolean = false;
  userMenu = [
    // {
    //   id: 1,
    //   title: "Profile",
    //   action: () => this.router.navigateByUrl("/account/profile"),
    // },
    { id: 2, title: "Log Out", action: () => this.core.logOut() },
  ];

  isDemoWorkspace: boolean = true;
  constructor(
    private sidebarService: NbSidebarService,
    private readonly core: CoreProvider,
    private menuService: NbMenuService,
    private readonly componentsService: ComponentsService,
    private router: Router,
    private themeService: NbThemeService,
  ) {
    this.isDemoWorkspace = environment.isDemo;
    // this.isDemoWorkspace = this.core.workspaceInfo.demo;
  }

  async ngOnInit() {
    this.userDataSubscription = this.core.getUserData().subscribe({
      next: (res) => {
        this.userData = res;
        if (!this.userData.avatar) {
          // this.profile.avatar = "assets/imgs/default-avatar.svg";
        } else {
          // this.profile.avatar = `data:image/png;base64,${this.profile.avatar}`;
        }
      }, error: (err) => {
        this.componentsService.showToast('danger', 'Se ha producido un erro al obtener el usuario', 'Error!');
      }
    })

    this.isUserAdmin = this.core.isUserAdmin();

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "user-options"),
        map(({ item }) => item as ExtendedNbMenuItem)
      )
      .subscribe((item) => {
        if (item.action) {
          item.action();
        } else {
          // TODO: Implementar redirección o acciones en los menú, cuando estén definidos
        }
      });
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
