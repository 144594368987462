/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AnsweredQuestionDto } from '../models/answered-question-dto';
import { ExerciseModelDto } from '../models/exercise-model-dto';
import { FormReplyDto } from '../models/form-reply-dto';
import { ResourceModelDto } from '../models/resource-model-dto';

@Injectable({
  providedIn: 'root',
})
export class ResourceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation resourceControllerGetResource
   */
  static readonly ResourceControllerGetResourcePath = '/resource';

  /**
   * Gets a resource, doesnt create channel resource. Only returns the resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerGetResource()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceControllerGetResource$Response(params?: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ResourceModelDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ResourceControllerGetResourcePath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.query('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ResourceModelDto>>;
      })
    );
  }

  /**
   * Gets a resource, doesnt create channel resource. Only returns the resource.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerGetResource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceControllerGetResource(params?: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid?: string;
    context?: HttpContext
  }
): Observable<Array<ResourceModelDto>> {

    return this.resourceControllerGetResource$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ResourceModelDto>>) => r.body as Array<ResourceModelDto>)
    );
  }

  /**
   * Path part for operation resourceControllerGetResourceByUuid
   */
  static readonly ResourceControllerGetResourceByUuidPath = '/resource/{uuid}';

  /**
   * Gets a resource type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerGetResourceByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceControllerGetResourceByUuid$Response(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResourceModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ResourceControllerGetResourceByUuidPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResourceModelDto>;
      })
    );
  }

  /**
   * Gets a resource type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerGetResourceByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceControllerGetResourceByUuid(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<ResourceModelDto> {

    return this.resourceControllerGetResourceByUuid$Response(params).pipe(
      map((r: StrictHttpResponse<ResourceModelDto>) => r.body as ResourceModelDto)
    );
  }

  /**
   * Path part for operation resourceControllerSendResource
   */
  static readonly ResourceControllerSendResourcePath = '/resource/{uuid}';

  /**
   * Manages the user form reply and categorizes it vulnerability, schedules its next campaign.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerSendResource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerSendResource$Response(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
    body: Array<FormReplyDto>
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ResourceControllerSendResourcePath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Manages the user form reply and categorizes it vulnerability, schedules its next campaign.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerSendResource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerSendResource(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
    body: Array<FormReplyDto>
  }
): Observable<void> {

    return this.resourceControllerSendResource$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation resourceControllerGetAnsweredQuestions
   */
  static readonly ResourceControllerGetAnsweredQuestionsPath = '/resource/{uuid}/answered-questions';

  /**
   * Returns answered questions of the profile that has requested this service.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerGetAnsweredQuestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceControllerGetAnsweredQuestions$Response(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AnsweredQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ResourceControllerGetAnsweredQuestionsPath, 'get');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AnsweredQuestionDto>>;
      })
    );
  }

  /**
   * Returns answered questions of the profile that has requested this service.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerGetAnsweredQuestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resourceControllerGetAnsweredQuestions(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
  }
): Observable<Array<AnsweredQuestionDto>> {

    return this.resourceControllerGetAnsweredQuestions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AnsweredQuestionDto>>) => r.body as Array<AnsweredQuestionDto>)
    );
  }

  /**
   * Path part for operation resourceControllerSendAnswer
   */
  static readonly ResourceControllerSendAnswerPath = '/resource/{uuid}/save-answer';

  /**
   * Manages the user form reply answer by answer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerSendAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerSendAnswer$Response(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
    body: FormReplyDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ResourceControllerSendAnswerPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Manages the user form reply answer by answer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerSendAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerSendAnswer(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
    body: FormReplyDto
  }
): Observable<void> {

    return this.resourceControllerSendAnswer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation resourceControllerCategorizePersonalityFormAnswers
   */
  static readonly ResourceControllerCategorizePersonalityFormAnswersPath = '/resource/{uuid}/categorize-personality-form-answers';

  /**
   * Categorize personality form answers and returns another extra exercise that shows the bis / bas / nc results.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resourceControllerCategorizePersonalityFormAnswers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerCategorizePersonalityFormAnswers$Response(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
    body: Array<FormReplyDto>
  }
): Observable<StrictHttpResponse<ExerciseModelDto>> {

    const rb = new RequestBuilder(this.rootUrl, ResourceService.ResourceControllerCategorizePersonalityFormAnswersPath, 'post');
    if (params) {
      rb.header('workspace', params.workspace, {});
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExerciseModelDto>;
      })
    );
  }

  /**
   * Categorize personality form answers and returns another extra exercise that shows the bis / bas / nc results.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resourceControllerCategorizePersonalityFormAnswers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resourceControllerCategorizePersonalityFormAnswers(params: {
    workspace?: string;

    /**
     * uuid to find by
     */
    uuid: string;
    context?: HttpContext
    body: Array<FormReplyDto>
  }
): Observable<ExerciseModelDto> {

    return this.resourceControllerCategorizePersonalityFormAnswers$Response(params).pipe(
      map((r: StrictHttpResponse<ExerciseModelDto>) => r.body as ExerciseModelDto)
    );
  }

}
