<!-- <ngx-chatbot></ngx-chatbot> -->




<!-- <ng-template #isUserView>
  <ng-container *ngIf="dashboard && core.isUserAdmin();">
  
        <div id="water-container" #eggContainer>
          <div class="text-center username mb-4">
            {{ this.userData?.name + ' ' + this.userData?.lastName }}
          </div>
          <div class="d-flex position-relative justify-content-center">
            <img class="d-flex position-absolute" id="water-image" #eggImage [src]="'assets/imgs/rings/ring' + getGeneralVulnerabilityEgg(dashboard?.generalVulnerabilityPrediction) + '.png'" alt="Dashboard">
            <img class="d-flex" id="water-image" #eggImage [src]="'assets/imgs/dashboard/purple_egg' + getGeneralVulnerabilityEgg(dashboard?.generalVulnerabilityPrediction) + '.png'" alt="Vulnerability level">
          </div>
          <div class="d-flex justify-content-center mt-4 gap">
            <strong>{{ 'Vulnerabilidad: '}}</strong>
            <span class="vulnerability-score">
              {{  getVulnerability(dashboard?.generalVulnerabilityPrediction) + ' %' }}
            </span>
          </div>
        </div>

    
  </ng-container>
</ng-template> -->
<ng-container *ngIf="dashboard && core.isUserAdmin(); else isUserView">
  <!-- <div class="container">
    <div class="row">
      <div *ngFor="let user of dashboard; let i = index" class="col-md-6 mb-4">
        <nb-flip-card #flipCard [showToggleButton]="false">
          <nb-card-front>
            <nb-card class="h-100">
              <nb-card-body>
                <div class="d-flex ">
                  <div class="d-flex flex-column flex-grow-1">
                    <span>
                      {{ user.email }}
                    </span>
                    <div *ngIf="user.bisLevel && user.basLevel && user.ncLevel">
                      <nb-tag-list (tagRemove)="onTagRemove($event)">
                        <nb-tag size="tiny" status="info" appearance="filled"
                          [text]="getTooltipText(user.bisLevel, 'bis')">
                        </nb-tag>
                        <nb-tag size="tiny" status="info" appearance="filled"
                          [text]="getTooltipText(user.basLevel, 'bas')">
                        </nb-tag>
                        <nb-tag size="tiny" status="info" appearance="filled"
                          [text]="getTooltipText(user.ncLevel, 'nc')">
                        </nb-tag>
                      </nb-tag-list>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <button nbButton status="info" [shape]="'round'" (click)="flipCard.toggle()" class="float-right">
                      Girar
                    </button>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-card-front>
          <nb-card-back class="h-100">
            <nb-card class="card-back-custom">
              <nb-card-body class="card-back-body d-flex justify-content-between">
                <div class="score-value">{{ user.currentVulnerabilityScore ?? 'No ha interactuado con EVE' }}</div>
                <button nbButton status="info" [shape]="'round'" (click)="flipCard.toggle()" class="float-right">
                  Volver
                </button>
              </nb-card-body>
            </nb-card>
          </nb-card-back>
        </nb-flip-card>
      </div>
    </div>
  </div> -->
  <div id="dashboard-container" #eggContainer>
    <div class="text-center username mb-4">
      {{ 'Workspace ' + core.workspace }}
    </div>
    <div *ngIf="eggImageSrc && ringImageSrc" class="d-flex position-relative justify-content-center">
      <img class="d-flex position-absolute" id="dashboard-image" #eggImage [src]="ringImageSrc" alt="Dashboard">
      <img class="d-flex" id="dashboard-image" #eggImage [src]="eggImageSrc" alt="Vulnerability level">
    </div>
    <div class="d-flex justify-content-center mt-4 gap">
      <strong>{{ 'Vulnerabilidad media de los usuarios: '}}</strong>
      <span class="vulnerability-score">
        {{ workspaceAverageVulnerability + ' %' }}
      </span>
    </div>
  </div>

</ng-container>

<ng-template #isUserView>
  <ng-container *ngIf="dashboard && !core.isUserAdmin();">
    <div class="d-flex justify-content-between h-100">
      <div id="dashboard-container" #eggContainer>
        <div *ngIf="!showPersonalText; else personalInfo" class="personal-info-card">
          <div>
            {{ 'Este es tu nivel individual. Se basa en tus rasgos de personalidad, loscontenidos y las simulaciones que has completado. Recuerda: A mayor nivel, más vulnerabilidad. Un nivel bajo significa que estás menos vulnerable a caer en un ataque. '}}
          </div>
          <button (click)="tooggleUserInfo()" nbButton hero [status]="'info'" [shape]="'round'">{{ 'Muéstramelo'}}</button>
        </div>
        <ng-template #personalInfo>
          <div class="personal-info-card">
            {{ getPersonalVariation() }}
          </div>
        </ng-template>
        <div class="w-25">
          <h2>{{ 'Estado'}}</h2>
          <div *ngIf="userData">
            {{ this.userData?.name + ', estos son tus niveles de vulnerabilidad, internos y externos.'}}
          </div>
        </div>
        <div *ngIf="eggImageSrc && ringImageSrc" class="d-flex w-50 position-relative justify-content-center">
          <img class="d-flex position-absolute" id="dashboard-image" #eggImage [src]="ringImageSrc" alt="Dashboard">
          <img class="d-flex" id="dashboard-image" #eggImage [src]="eggImageSrc" alt="Vulnerability level">
        </div>
        <div *ngIf="!showGlobalText; else globalInfo" class="global-info-card">
          <div>
            {{ 'El exterior compara tu nivel con el resto de tu equipo. Recuerda: Un nivel alto significa que tu vulenrabilidad es más alta que el promedio. Y uno bajo implica una vulnerabilidad menor que el promedio.'}}
          </div>
          <button (click)="toogleRingInfo()" nbButton hero [status]="'info'" [shape]="'round'">{{ 'Muéstramelo'}}</button>
        </div>
        <ng-template #globalInfo>
          <div class="global-info-card">
            {{ getGlobalVariation() }}
          </div>
        </ng-template>
      </div>
    </div>


  </ng-container>
</ng-template>


<ng-template #modalConfirm let-data let-ref="dialogRef">
  <ngx-confirm-workspace-modal (closeModal)="closeModalConfirm(ref)"></ngx-confirm-workspace-modal>
</ng-template>