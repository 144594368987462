import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { ApiService } from "./api.service";
import { environment } from "../../../environments/environment";
import { DashboardDto, ProfileModelDto, ReducedWorkspaceDto, WorkspaceModelDto } from "../api/models";
import { BehaviorSubject, Observable, filter, tap } from "rxjs";
import { Router } from "@angular/router";

export const WORKSPACE: string | null =
  location.host
    .replace(environment.appBaseUrl, "")
    .replace(/\-$/g, "")
    .split("-")[0] ?? null;

@Injectable({
  providedIn: "root",
})
export class CoreProvider {
  public readonly workspace: string | null = WORKSPACE;
  private _userData = new BehaviorSubject<ProfileModelDto | null>(null);
  private _dashboard = new BehaviorSubject<DashboardDto[] | null>(null);
  private _workspace = new BehaviorSubject<ReducedWorkspaceDto | null>(null);

  private _isFetchingUserData = false;
  private _isFetchingDashboard = false;
  private _isFetchingWorkspace = false;

  get roles(): string[] {
    if (!this.keycloak.isLoggedIn()) return [];
    return this.keycloak.getUserRoles();
  }

  constructor(
    public readonly keycloak: KeycloakService,
    public readonly api: ApiService,
    public readonly router: Router,
  ) {
    console.log("CoreProvider initialized", this.keycloak);
    if (!environment.production) window['core'] = this;
  }

  async logOut() {
    await this.keycloak.logout();
  }

  isUserAdmin(): boolean {
    return this.keycloak.isLoggedIn() && this.keycloak.getUserRoles().includes('eve-admin');
  }

  getUserData(): Observable<ProfileModelDto | null> {
    if (!this._userData.value && !this._isFetchingUserData) {
      this._isFetchingUserData = true;
      this.fetchUserData().subscribe({
        complete: () => this._isFetchingUserData = false,
      });
    }
    return this._userData.asObservable().pipe(
      filter(data => data !== null)
    );
  }

  getDashboardData(): Observable<DashboardDto[] | null> {
    if (!this._dashboard.value && !this._isFetchingDashboard) {
      this._isFetchingDashboard = true;
      this.fetchDashboardData().subscribe({
        complete: () => this._isFetchingDashboard = false,
      });
    }
    return this._dashboard.asObservable().pipe(
      filter(data => data !== null)
    );
  }

  fetchDashboardData(): Observable<DashboardDto[]> {
    return this.api.me.meControllerDashboard().pipe(
      tap({
        next: (data: DashboardDto[]) => this._dashboard.next(data),
        error: (error: any) => {
          console.error('Error al obtener el dashboard del usuario', error);
        }
      })
    );
  }

  fetchUserData(): Observable<ProfileModelDto> {
    return this.api.me.meControllerMe().pipe(
      tap({
        next: (data: ProfileModelDto) => this._userData.next(data),
        error: (error: any) => {
          console.error('Error al obtener el perfil del usuario', error);
        }
      })
    );
  }

  refreshUserData(): Observable<ProfileModelDto> {
    return this.fetchUserData().pipe(
      tap({
        next: (data: ProfileModelDto) => this._userData.next(data)
      })
    );
  }

  refreshDashboardData(): Observable<DashboardDto[]> {
    return this.fetchDashboardData().pipe(
      tap({
        next: (data: DashboardDto[]) => this._dashboard.next(data)
      })
    );
  }

  
}
