import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CoreProvider } from '../../../../../services/services/core.provider';
import { KeycloakUserDto, ProfileModelDto } from '../../../../../services/api/models';
import { PaginationService } from '../../../../../services/services/pagination.service';
import { ComponentsService } from '../../../../../services/services/components.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-tab-table-confirm',
  templateUrl: './tab-table-confirm.component.html',
  styleUrls: ['./tab-table-confirm.component.scss']
})
export class TabTableConfirmComponent implements AfterViewInit, OnDestroy, OnInit {
  userData: ProfileModelDto | null = null;
  userDataSubscription: Subscription;
  @Output("refreshPage") refreshPage: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = true;
  profiles: any[];
  paginatedProfiles: any[] = [];

  @Input() type: string;
  isNewProfileModal: boolean = false;
  editionForm: FormGroup = new FormGroup({
    email: new FormControl(null),
    name: new FormControl(null),
    surname: new FormControl(null)
  });

  @ViewChild('modalEdit') modalEdit: TemplateRef<any>;
  @Input() set rows (value) {
    if (value) {
      this.profiles = value;
      this.confirmationProfiles = [...value];
      this.updatePagination();
    }
  };
  pagination = {
    page: {
      pageNumber: 0,
      pageSize: 5,
      totalElements: 0,
      totalPages: 0,
    }
  };
  @Input() page: any;
  @Input() set totalElements(value: number) {
    if (value) {
      this.pagination.page.totalElements = value;
      this.pagination.page.totalPages = Math.ceil(value / this.pagination.page.pageSize);
    }
  }

  confirmationProfiles: any[] = [];
  @Input() columns: any[];

  constructor(private readonly core: CoreProvider, private componentsService: ComponentsService) {
  }
  
  ngOnInit(): void {
    this.loadUserData();
  }
  ngAfterViewInit() {
    if (this.rows) {
      this.confirmationProfiles = [...this.rows];
    }
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }
  getUpperCase(status) {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  async loadUserData() {
    try {
      this.userDataSubscription = this.core.getUserData().subscribe({
        next: (res) => {
          this.userData = res;
        }, error: (err) => {
          this.componentsService.showToast('danger', 'Error en la obtención del usuario', 'Error!')
        }
      })
    } catch (error) {
      console.error('Error al cargar los datos del usuario', error);
    }
  }

  isUserDataLoaded(): boolean {
    return this.userData !== null;
  }

  rejectProfile(row: ProfileModelDto) {
    let found = this.confirmationProfiles.find(itm => {
      if (row.uuid) {
        return itm.uuid === row.uuid;
      } else {
        return itm.email === row.email;
      }
    });
    
    if (found) {
      this.confirmationProfiles = this.confirmationProfiles.filter(itm => itm !== found);
    }
    this.confirmationProfiles = [...this.confirmationProfiles];
  }

  acceptProfile(row: any) {
    let found = this.confirmationProfiles.find(itm => {
      if (row.uuid) {
        return itm.uuid === row.uuid;
      } else {
        return itm.email === row.email;
      }
    });
    
    if (!found) {
      this.confirmationProfiles.push(row);
    }
    this.confirmationProfiles = [...this.confirmationProfiles];
  }

  isRowOnConfirmProfiles(row: ProfileModelDto) {
    if (this.confirmationProfiles) {
      return this.confirmationProfiles.find(itm => {
        if (itm.uuid && row.uuid) {
          return itm.uuid === row.uuid;
        } else if (itm.email && row.email) {
          return itm.email === row.email;
        }
        return false;
      });
    } else {
      return null;
    }
  }

  paginateProfiles() {
    const startIndex = this.pagination.page.pageNumber * this.pagination.page.pageSize;
    const endIndex = startIndex + this.pagination.page.pageSize;
    this.paginatedProfiles = this.profiles.slice(startIndex, endIndex);
  }

  updatePagination() {
    this.pagination.page.totalElements = this.profiles.length;
    this.pagination.page.totalPages = Math.ceil(this.pagination.page.totalElements / this.pagination.page.pageSize);
    this.pagination.page.pageNumber = Math.max(0, Math.min(this.pagination.page.pageNumber, this.pagination.page.totalPages - 1));
    this.paginateProfiles();
  }

  onPageSelect(pageNumber: number) {
    this.pagination.page.pageNumber = pageNumber;
    this.paginateProfiles();
  }

  prevPage() {
    if (this.pagination.page.pageNumber > 0) {
      this.pagination.page.pageNumber--;
      this.paginateProfiles();
    }
  }

  nextPage() {
    if (this.pagination.page.pageNumber < this.pagination.page.totalPages - 1) {
      this.pagination.page.pageNumber++;
      this.paginateProfiles();
    }
  }

  refreshPageEmitter() {
    this.refreshPage.emit(this.pagination.page);
  }

  deleteProfile(row) {
    // Eliminar el perfil de la lista completa de perfiles
    this.profiles = this.profiles.filter(itm => itm.email !== row.email);
    this.confirmationProfiles = this.confirmationProfiles.filter(itm => itm.email !== row.email);
    this.pagination.page.totalElements--;
    this.pagination.page.totalPages = Math.ceil(this.pagination.page.totalElements / this.pagination.page.pageSize);
    this.pagination.page.pageNumber = Math.min(this.pagination.page.pageNumber, this.pagination.page.totalPages - 1);
    this.paginateProfiles();
  }

  showEditModal(row?) {
    if (row) {
      this.editionForm.patchValue({
        email: row.email,
        name: row.firstName,
        surname: row.lastName
      });
      this.editionForm.get('email').disable();
      this.componentsService.openModal(this.modalEdit, row, true);
    } else {
      this.editionForm.reset();
      this.editionForm.get('email').enable();
      this.isNewProfileModal = true;
      this.componentsService.openModal(this.modalEdit, null, true);
    }
  }

  submitForm(ref, row?) {
    if (row) {
      row.firstName = this.editionForm.get('name').value;
      row.lastName = this.editionForm.get('surname').value;
      this.editionForm.reset();
    } else {
      const email = this.editionForm.get('email').value;
      const existingProfile = this.profiles.find(profile => profile.email.toLowerCase() === email.toLowerCase());
    
      if (existingProfile) {
        this.componentsService.showToast('danger', 'Ya existe un perfil con ese email', 'Error');
        ref.close();
        return
      }
      this.profiles.push({
        firstName: this.editionForm.get('name').value,
        lastName: this.editionForm.get('surname').value,
        email: this.editionForm.get('email').value,
      });
      this.confirmationProfiles.push({
        firstName: this.editionForm.get('name').value,
        lastName: this.editionForm.get('surname').value,
        email: this.editionForm.get('email').value,
      });
      this.editionForm.reset();
      this.pagination.page.totalElements++;
      this.pagination.page.totalPages = Math.ceil(this.pagination.page.totalElements / this.pagination.page.pageSize);
      this.updatePagination(); // Actualizar la paginación después de añadir un nuevo perfil
    }
    this.paginateProfiles();
    ref.close();
  }
}
