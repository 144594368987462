import { Injectable, Type } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { KeycloakService } from "keycloak-angular";
import { CoreProvider } from "./core.provider";
import { WorkspaceModelDto } from "../api/models";
import { ConfirmWorkspaceModalComponent } from "../../main-module/account-module/dashboard/components/confirm-workspace-modal/confirm-workspace-modal.component";
import { ComponentsService } from "./components.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class InitService {
  private dialogRef: NbDialogRef<any>;
  userDataSubscription: Subscription;
  constructor(
    private readonly dialogService: NbDialogService,
    private readonly core: CoreProvider,
    private readonly componentsService: ComponentsService,
    private readonly router: Router
  ) { }

  async checkAction(): Promise<void> {
    if (this.core.keycloak.isLoggedIn()) {
      const userRoles = this.core.roles;

      if (userRoles.includes("eve-admin")) {
        this.core.api.workspace
          .workspaceControllerGetAllWorkspaceInfo()
          .subscribe({
            next: (res: WorkspaceModelDto) => {
              if (!res.approved) {
                this.openModal(ConfirmWorkspaceModalComponent, {
                  workspace: res,
                  hasBackdrop: true,
                  closeOnBackdropClick: false,
                  closeOnEsc: false,
                });
              }
            },
          });
      } else if (userRoles.includes("eve-user")) {
        this.userDataSubscription = this.core.getUserData().subscribe({
          next: (res) => {
            let userData = res;
            if (userData.requiredAction) {
              if (userData.requiredAction.includes('resource_type:')) {
                const uuid = this.getActionUuidString(userData.requiredAction);
                this.router.navigateByUrl(`/account/eve/${uuid}/resource`);
              } else if (userData.requiredAction.includes('storytelling:')) {

              }
            }
          }, error: (err) => {
            this.componentsService.showToast('danger', 'Error en la obtención del usuario', 'Error!')
          }
        });
        // this.core
        //   .getUserData()
        //   .then((user) => {
        //     if (user.requiredAction) {
        //       if (user.requiredAction.includes('resource_type:')){
        //         this.openModal(FormModalComponent, {
        //           actionUuid: this.getActionUuidString(user.requiredAction),
        //           hasBackdrop: true,
        //           closeOnBackdropClick: false,
        //           closeOnEsc: false,
        //         });
        //       } else if (user.requiredAction.includes('storytelling:')){
        //         this.openModal(StorytellingModalComponent, {
        //           actionUuid: this.getActionUuidString(user.requiredAction),
        //           hasBackdrop: true,
        //           closeOnBackdropClick: false,
        //           closeOnEsc: false,
        //         });
        //       }
        //     }
        //   })
        //   .catch((error) => {
        //     console.error("Error al obtener los datos del usuario", error);
        //   });
      }
    } else {
      // FIXME: Do not require everytime the user to login since there are even public pages, just subscribe to the user login and check action after login
      // this.core.keycloak.login();
    }
  }

  async checkUserRequiredAction() {
    this.userDataSubscription = this.core.getUserData().subscribe({
      next: (res) => {
        let userData = res;
        if (userData.requiredAction) {
          return userData;
        }
      }, error: (err) => {
        this.componentsService.showToast('danger', 'Error en la obtención del usuario', 'Error!')
      }
    });
    // return new Promise((resolve) => {
    //   this.actRouter.queryParams.subscribe((params) => {
    //     if (params['action']) {
    //       this.openModal(FormModalComponent, { actionUuid: params['action'], hasBackdrop: true,
    //       closeOnBackdropClick: false,
    //       closeOnEsc: false});
    //       resolve(true);
    //     } else {
    //       resolve(false);
    //     }
    //   });
    // });
  }

  openModal(component: any, data: any) {
    this.dialogRef = this.dialogService.open(component, {
      context: data,
      hasBackdrop: true,
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  closeModal(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getActionUuidString(actionString: string) {
    if (actionString) {
      const parts = actionString.split(":");
      if (parts.length === 2) {
        return parts[1].trim(); // Devuelve el UUID sin espacios adicionales
      }
    }
  }
}
