import { NgModule } from '@angular/core';
import { NbAccordionModule, NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbMenuModule, NbProgressBarModule, NbTabsetComponent, NbTabsetModule, NbTagModule, NbTooltipModule } from '@nebular/theme';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DashboardComponent } from './dashboard.component';
import { TabTableConfirmComponent } from './components/tab-table-confirm/tab-table-confirm.component';
import { CommonModule } from '@angular/common';
import { GeneralComponentsModule } from '../components/components.module';
import { AcceptConditionsComponent } from './components/accept-conditions/accept-conditions.component';
import { ConfirmWorkspaceModalComponent } from './components/confirm-workspace-modal/confirm-workspace-modal.component';
import { ComponentsDashboardModule } from './components/components.dashboard.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';


const COMPONENTS = [
  DashboardComponent
];

@NgModule({
  imports: [
    ComponentsDashboardModule,
    NbTabsetModule,
    NbCardModule,
    NbTagModule,
    NbMenuModule,
    NbProgressBarModule,
    NbButtonModule,
    NgxChartsModule,
    NbAlertModule,
    NbAccordionModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbIconModule,
    NgxDatatableModule,
    CommonModule,
    GeneralComponentsModule
  ],
  declarations: [
    ...[COMPONENTS]
  ],
  providers: [
  ],
  exports:[]
})
export class DashboardModule {
}
