import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './services/guards/auth.guard';

export const routes: Routes = [
  { path: 'public', loadChildren: () => import('./public-module/public.module').then(m => m.PublicModule) },
  { path: '', loadChildren: () => import('./main-module/main.module').then(m => m.MainModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
