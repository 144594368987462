<div class="header-container">
  <div class="logo-container">
    <img src="assets/imgs/logo_eve_intelligence_no_background.png" alt="EVE" width="120" height="50">
    <div class="d-flex justify-content-center align-items-center">
  
      <nb-icon nbTooltip="Mi diseño sigue en construcción, pero mi cerebro ya funciona al 100%. Gracias por ser parte del proceso. Atentamente: EVE Intelligence"
      nbTooltipClass="custom-tooltip" nbTooltipPlacement="bottom" class="d-flex ml-2 info-icon align-items-center" icon="info-outline" pack="eva"></nb-icon>
      <!-- <nb-actions [size]="'small'">
        <nb-action nbTooltip="Mi diseño sigue en construcción, pero mi cerebro ya funciona al 100%. Gracias por ser parte del proceso. Atentamente: EVE Intelligence"
                   nbTooltipClass="custom-tooltip" nbTooltipPlacement="bottom" icon="info-outline">
        </nb-action>
      </nb-actions> -->
    </div>
    <!-- <a class="logo" href="#" (click)="navigateHome()"><span>EVE-PLATFORM-FRONT</span></a> -->
  </div>
</div>



<div *ngIf="!isDemoWorkspace || isUserAdmin" class="header-container">
  <nb-actions size="small" class="avatar-container">
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu" [nbContextMenuAdjustment] nbContextMenuTag="user-options" size="small" [onlyPicture]="false"
        [picture]="userData?.avatar" [showInitials]="true" [name]="userData?.name" [color]="'#d5eb73'">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>