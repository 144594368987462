import { Component, OnInit } from '@angular/core';
import { InitService } from './services/services/init.service';
import { MenuService } from './services/services/menu.service';

@Component({
  selector: 'ngx-app',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(private readonly menuService: MenuService, private readonly initService: InitService) {}
  async ngOnInit(): Promise<void> {
    await this.menuService.updateMenuItems();
    await this.initService.checkAction();
  }
}
