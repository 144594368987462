<nb-card class="h-100 custom-modal">
  <nb-card-body>
    <section class="w-100 h-100 d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between w-100">
        <h2 class="mb-3">{{ 'Confirmación Workspace' }}</h2>
        <div *ngIf="this.tabActive === 1"><nb-actions [nbTooltip]="'Añadir perfil'" [size]="'large'" >
          <nb-action icon="plus-circle-outline" (click)="newProfileModal()"></nb-action>
        </nb-actions></div>
      </div>
      <section class="d-flex flex-column mb-3">
        <div class="d-flex gap-2 mb-2">
          <span>{{'Nombre Workspace:'}}</span>
          <span>{{ workspace?.name}}</span>
        </div>
        <div class="d-flex gap-2 mb-2">
          <span>{{'Realm Keycloak:'}}</span>
          <span>{{ workspace?.keycloakRealm}}</span>
        </div>
        <div class="d-flex gap-2 mb-2">
          <span>{{'URL Servidor Keycloak:'}}</span>
          <span>{{ workspace?.keycloakServerUrl}}</span>
        </div>
      </section>
      <section class="flex-grow-1 overflow-auto">
        <nb-tabset fullWidth (changeTab)="changeTab($event)">
          <nb-tab *ngFor="let tab of tabList" [tabTitle]="tab.tabName">
            <div class="tab-content">
              <ngx-tab-table-confirm *ngIf="tabActive === 1" #table [columns]="columns"
              [pagination]="paginationService" [page]="page"
              (refreshPage)="updatePage($event)" [confirmationProfiles]="confirmationProfiles" [totalElements]="totalElements" [rows]="rows"></ngx-tab-table-confirm>
              <ngx-accept-conditions *ngIf="tabActive === 2"></ngx-accept-conditions>
            </div>
          </nb-tab>
        </nb-tabset>
      </section>
      <div class="d-flex w-100 mt-2" [ngClass]="{'justify-content-between': tabActive === 1, 'justify-content-end': tabActive === 2}">
        <div *ngIf="tabActive === 1" class="d-flex gap">
          <button nbButton outline status="info" size="medium" [nbTooltipStatus]="'info'" [shape]="'round'"
            [nbTooltip]="'Descargar plantilla Excel'" (click)="downloadTemplate()"><nb-icon icon="file-excel"
              pack="fa"></nb-icon></button>
          <button nbButton status="info" size="medium" [nbTooltipStatus]="'info'" [shape]="'round'"
            [nbTooltip]="'Importar perfiles desde CSV'" (click)="fileInput.click()"><nb-icon icon="arrow-up-from-bracket"
              pack="fa"></nb-icon></button>
        </div>
        <input #fileInput nbInput [type]="'file'" accept=".csv" (change)="onFileChange($event)" style="display: none;">
        <button nbButton [nbSpinner]="isLoading" nbSpinnerStatus="info" nbSpinnerSize="large" nbSpinnerMessage="" shape="round" (click)="confirmWorkspaceCreation()" status="info">{{ 'Confirmar creación workspace'}}</button>
      </div>
    </section>
  </nb-card-body>
</nb-card>