import { NgModule } from '@angular/core';
import { NbAccordionModule, NbActionsModule, NbAlertModule, NbButtonModule, NbCardModule, NbChatCustomMessageService, NbChatModule, NbCheckboxModule, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbSpinnerModule, NbTabsetComponent, NbTabsetModule, NbTooltipModule, NbUserModule } from '@nebular/theme';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule } from '@angular/common';
import { TabTableConfirmComponent } from './tab-table-confirm/tab-table-confirm.component';
import { AcceptConditionsComponent } from './accept-conditions/accept-conditions.component';
import { ConfirmWorkspaceModalComponent } from './confirm-workspace-modal/confirm-workspace-modal.component';
import { GeneralComponentsModule } from '../../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';

const COMPONENTS = [
  TabTableConfirmComponent,
  AcceptConditionsComponent,
  ConfirmWorkspaceModalComponent,
];

@NgModule({
  imports: [
    NbTabsetModule,
    NbCardModule,
    NbSpinnerModule,
    NbMenuModule,
    NbButtonModule,
    NbAlertModule,
    NbUserModule,
    NbSelectModule,
    NbActionsModule,
    NbChatModule,
    NbAccordionModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbIconModule,
    NgxDatatableModule,
    CommonModule,
    GeneralComponentsModule
  ],
  declarations: [
    ...[COMPONENTS]
  ],
  providers: [NbChatCustomMessageService],

  exports:[...[COMPONENTS]]
})
export class ComponentsDashboardModule {
}
