import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  Base64FileDto,
  CreatedUsersDto,
  ProfileModelDto,
  RegisterDto,
  WorkspaceModelDto,
} from "../../../../../services/api/models";
import { CoreProvider } from "../../../../../services/services/core.provider";
import { Pagination } from "../../../../../services/services/utils/pagination.interface";
import { ComponentsService } from "../../../../../services/services/components.service";
import { FormControl, FormGroup } from "@angular/forms";
import { DownloaderService } from "../../../../../services/services/downloader.service";
import { TabTableConfirmComponent } from "../tab-table-confirm/tab-table-confirm.component";
import { PaginationService } from "../../../../../services/services/pagination.service";
import { InitService } from "../../../../../services/services/init.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "ngx-confirm-workspace-modal",
  templateUrl: "./confirm-workspace-modal.component.html",
  styleUrls: ["./confirm-workspace-modal.component.scss"],
})
export class ConfirmWorkspaceModalComponent implements OnInit {
  @Input() workspace: WorkspaceModelDto;
  @ViewChild("table") table: TabTableConfirmComponent;
  isLoading: boolean = false;
  @ViewChild("modalConfirm") modalConfirm: TemplateRef<any>;
  rows: any[];
  columns: any[];

  csvData: any[] = [];
  csvErrors: string[] = [];

  totalElements: number = 0;

  tabActive: number = 1;
  tabList = [
    {
      tabName: "Perfiles",
      tableType: "profile",
      tabActive: 1,
    },
    // {
    //   tabName: "Términos y condiciones",
    //   tableType: "campaign",
    //   tabActive: 2
    // }
  ];

  constructor(
    public readonly core: CoreProvider,
    public readonly componentsService: ComponentsService,
    private readonly downloadService: DownloaderService,
    readonly paginationService: PaginationService,
    private readonly initService: InitService,
    private readonly router: Router
  ) {}

  ngOnInit() {}

  showConfirmWorkspaceModal() {
    this.componentsService.openModal(this.modalConfirm, null, false);
    if (this.modalConfirm.elementRef.nativeElement) {
      this.core.api.workspace
        .workspaceControllerGetAllWorkspaceInfo()
        .subscribe({
          next: (res: WorkspaceModelDto) => {
            this.workspace = res;
          },
        });
    }
  }

  changeTab(event: any): void {
    const selectedTab = this.tabList.find(
      (tab) => tab.tabName === event.tabTitle
    );
    if (selectedTab) {
      this.tabActive = selectedTab.tabActive;
    }
    this.refresh();
  }
  refresh() {
    if (this.tabActive) {
      this.table.isLoading = true;
      switch (this.tabActive) {
        case 1:
          this.core.api.workspace
            .workspaceControllerGetPendingUsersInWorkspace$Response({
              limit: 9999,
              page: 0,
            })
            .subscribe({
              next: (res) => {
                this.rows = res.body;
                this.totalElements = Number(
                  res.headers.get("x-pagination-count")
                );
                this.columns = [
                  { name: "Nombre", prop: "firstName" },
                  { name: "Apellidos", prop: "lastName" },
                  { name: "Email", prop: "email" },
                ];
                this.table.isLoading = true;
              },
              error(err) {
                this.componentsService.showToast(
                  "danger",
                  "Se ha producido un error en la carga de perfiles",
                  "Error!"
                );
              },
            });
          break;
        case 2:
          break;
        default:
          break;
      }
    }
  }

  confirmWorkspaceCreation() {
    this.isLoading = true;
    let body: RegisterDto[] = [];
    this.table.confirmationProfiles.map((itm) => {
      body.push({
        firstName: itm.firstName,
        lastName: itm.lastName,
        email: itm.email,
        enabled: true,
        role: "eve-user",
      });
    });
    if (body) {
      this.core.api.workspace
        .workspaceControllerEnableWorkspace({ body })
        .subscribe({
          next: (res) => {
            this.manageCreationAlert(res);
          },
          error: (err) => {
            this.componentsService.showToast(
              "danger",
              "Se ha producido un error al confirmar el workspace",
              "Error!"
            );
            this.isLoading = false;
          },
        });
    }
  }

  manageCreationAlert(res: CreatedUsersDto) {
    if (res.incorrectUsers.length !== 0 && res.correctUsers.length !== 0) {
      for (const al of res.correctUsers) {
        this.componentsService.showToast(
          "success",
          `El usuario ${al.email} se ha creado correctamente`,
          "Usuarios"
        );
      }
      for (const al of res.incorrectUsers) {
        this.componentsService.showToast(
          "danger",
          `Hubo un error al crear el usuario ${al.email}: ${al.error}`,
          "Usuarios"
        );
      }
    }
    if (res.incorrectUsers.length !== 0 && res.correctUsers.length === 0) {
      for (const al of res.incorrectUsers) {
        this.componentsService.showToast(
          "danger",
          `Hubo un error al crear el usuario ${al.email}: ${al.error}`,
          "Usuarios"
        );
      }
    }
    if (res.incorrectUsers.length === 0 && res.correctUsers.length !== 0) {
      this.componentsService.showToast(
        "success",
        "Se han creado los usuarios correctamente",
        "Usuarios"
      );
    }
    if (res.incorrectUsers.length === 0 && res.correctUsers.length === 0) {
      this.componentsService.showToast(
        "success",
        "Se han confirmado los perfiles sin errores",
        "Usuarios"
      );
    }
    this.router.navigateByUrl(`/account/dashboard?reload=true`);
    this.isLoading = false;
    this.closeModal();
  }

  closeModal(): void {
    this.initService.closeModal();
  }
  async onFileChange(event) {
    try {
      const csvContent: string = await this.downloadService.importCsvFile(
        event
      );
      const parsedData = this.downloadService.parseCsv(csvContent);

      // Lista de campos requeridos que queremos validar
      const requiredFields = ["Nombre", "Apellido", "Email"];
      const validation = this.downloadService.validateCsvData(
        parsedData,
        requiredFields
      );

      if (validation.valid) {
        this.csvData = parsedData;

        const existingEmails = new Set(
          this.rows.map((row) => row.email.toLowerCase())
        ); // Conjunto de emails existentes en minúsculas para evitar duplicados por diferencia de mayúsculas/minúsculas

        this.csvData.forEach((itm) => {
          const email = itm.Email.toLowerCase();
          if (!existingEmails.has(email)) {
            const newProfile = {
              firstName: itm.Nombre,
              lastName: itm.Apellido,
              email: itm.Email,
            };
            this.rows.push(newProfile);
            existingEmails.add(email); // Añadir el nuevo email al conjunto

            // Añadir el perfil al array de confirmationProfiles del componente hijo
            this.table.acceptProfile(newProfile);
          }
        });

        // Actualizar la paginación en el componente hijo después de añadir los perfiles del CSV
        this.table.updatePagination();

        this.csvErrors = [];
      } else {
        this.csvErrors = validation.errors;
      }
      this.showImportAlerts(validation);
    } catch (error) {
      this.componentsService.showToast(
        "danger",
        "Se ha producido un error al leer el CSV",
        "Usuarios"
      );
    }
  }

  // Método para actualizar confirmationProfiles en el componente hijo
  updateConfirmationProfiles(newProfile) {
    if (this.table) {
      this.table.confirmationProfiles.push(newProfile);
      this.table.updatePagination();
    }
  }

  // Método para actualizar la paginación en el componente hijo
  updatePagination() {
    if (this.table) {
      this.table.updatePagination();
    }
  }

  showImportAlerts(validation) {
    if (!validation.valid) {
      let error: string;
      validation.error.forEach((element) => {
        error = element + "<br>";
      });
      this.componentsService.showToast("danger", `${error}`, "Error!");
    }
  }
  downloadTemplate() {
    this.core.api.workspace
      .workspaceControllerDownloadProfileCreationTemplate()
      .subscribe({
        next: (res) => {
          this.downloadService.downloadFromBase64(
            res?.fileContent,
            res?.fileName,
            res?.fileType
          );
        },
        error: (err) => {
          this.componentsService.showToast(
            "danger",
            "Se ha producido un error en la descarga.",
            "Error!"
          );
        },
      });
  }

  updatePage(event) {
    this.paginationService.page.pageNumber = event.pageNumber;
    this.paginationService.page.size = event.size;
    this.refresh();
  }

  changeSize() {
    this.paginationService.page.pageNumber = 0;
    this.refresh();
  }

  newProfileModal() {
    this.table.showEditModal();
  }
}
