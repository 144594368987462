import { NgModule } from '@angular/core';
import { NbAlertModule, NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule } from '@nebular/theme';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EveToogleComponent } from './eve-toogle-component/eve-toogle-component.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CommonModule } from '@angular/common';


const COMPONENTS = [
  EveToogleComponent,
  PaginationComponent,
];

@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbMenuModule,
    NbButtonModule,
    NbAlertModule,
    NbSelectModule,
    FormsModule,
    NbIconModule,
    NbInputModule,
    ReactiveFormsModule,
    NgxDatatableModule
  ],
  declarations: [
    ...[COMPONENTS]
  ],
  providers: [
  ],
  exports: [...COMPONENTS]
})
export class GeneralComponentsModule {
}
